<template>
  <div class="AccountAudit">
    <div class="AccountAuditHeader">
      <span>账号审核</span>

      <div class="audit-type">
        <el-radio-group v-model="audit_type" @change="handleChangeAudit">
          <el-radio :label="1">自动审核</el-radio>
          <el-radio :label="2">手动审核</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="selectClass">
      <div class="select-left">
        <span>筛选</span>
        <el-select style="margin: 0 16px" size="mini" v-model="auditValue" placeholder="请选择" @change="selectAudit">
          <el-option v-for="item in auditList" :key="item.status" :label="item.name" :value="item.status">
          </el-option>
        </el-select>
      </div>
        <el-select class="select-right" size="mini" v-model="selectValue" placeholder="请选择" @change="isSelectClass">
          <el-option v-if="classList.length>0" :key="0" label="全部" :value="0"></el-option>
          <el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
    </div>
      <el-table :data="accountList" style="width: 100%;flex: 1;" height="1%" size="medium"
                :header-cell-style="{fontWeight: 'normal', height: '50px', color: '#222222', background: '#F8F8FD',fontSize: '14px'}"
                :cell-style="{fontSize: '14px',color: '#333333',height: '75px'}">
        <el-table-column type="index" align="center" label="序号" width="150"></el-table-column>
        <el-table-column prop="name" align="center" label="学生名称"></el-table-column>
        <el-table-column prop="class_name" align="center" label="班级"></el-table-column>
        <el-table-column prop="classification" align="center" label="所属平台">
          <template slot-scope="scope">
            <span>{{ scope.row.classification == 1 ? '抖音' : scope.row.classification == 2 ? '快手' : scope.row.classification == 3 ? '哔哩哔哩' : scope.row.classification == 4 ? '美拍' : scope.row.classification == 5 ? '西瓜视频' : scope.row.classification == 6 ? '火山视频' : '未知' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="account_link" align="center" label="账号" >
          <template slot-scope="scope">
               <div style="display: flex;justify-content: start;align-items: center;">
                <a v-if="scope.row.index_url" target="_blank" style="color: #2338E6;text-decoration:underline"
                :href="scope.row.index_url"> 
                  <img style="width: 34px;height: 34px;border-radius: 34px;object-fit: cover;" :src="scope.row.avatar" alt="">
                </a>
                <img v-else style="width: 34px;height: 34px;border-radius: 34px;object-fit: cover;" :src="scope.row.avatar" alt="">
                <span style="margin-left: 5px;">{{ scope.row.nickname }}</span>
               </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" v-if="audit_type === 2">
          <template slot-scope="scope">
            <el-button v-if="scope.row.audit_status === 0"
                        style="height: 32px;line-height: 32px;font-size: 14px;padding:0 29px" type="primary"
                        @click="accountAudit(scope.row)">审核
            </el-button>
            <span style="color: #9ACC5B" v-if="scope.row.audit_status === 1">审核通过</span>
            <span style="color: #FF0000" v-if="scope.row.audit_status === 2">驳回审核</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          class="pages-center"
          :current-page="adminPages.currentPageNum"
          :page-size="adminPages.eachPageNum"
          layout="prev, pager, next, jumper"
          :total="adminPages.total"
          @current-change="adminCurrentChange"
      ></el-pagination>
      <el-dialog
          title="账号审核"
          :visible.sync="dialogVisible"
          width="400px">
        <p style="color: #333333;margin-left: 16%">学生：{{ studentName }}</p>
        <div style="color: #333333;margin-left: 16%;display: flex;align-items: center;">账号：
          <!-- <a style="color: #2338E6;text-decoration: underline" target="_blank" :href="studentAccount">{{ studentAccount }}</a> -->
          <div style="display: flex;justify-content: center;align-items: center;">
                <img style="width: 34px;height: 34px;border-radius: 34px;object-fit: cover;" :src="avatar" alt="">
                <span style="margin-left: 5px;">{{ nickname }}</span>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="auditNoPass">驳回审核</el-button>
            <el-button type="primary" @click="auditPass">审核通过</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'AccountAudit',
  data() {
    return {
      auditValue:'',
      auditList:[
        {
          status:0,
          name:'全部'
        },
        {
          status:1,
          name:'已审核'
        },
        {
          status:2,
          name:'未审核'
        },
      ],
      selectValue: '',
      classList: [],
      accountList: [],
      accountSelectList: [],
      studentName: '',
      studentAccount: '',
      dialogVisible: false,
      accountId: 0,
      accountStatus: 0,
      adminPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0
      },
      avatar:"",
      nickname:"",
      audit_type: 0,
    }
  },
  created() {
    this.getSelectClass();
    this.getAccountList();
    this.getAuditConfig();
  },
  methods: {
    //选择班级列表
    getSelectClass() {
      let param = {};
      this.$httpteacher.axiosGetBy(this.$api.classList, param, (res) => {
        if (res.code == 200) {
          this.classList = res.list;
        }
      })
    },
    // 获取账号审核列表
    getAccountList() {
      let param = {
        page: this.adminPages.currentPageNum,
        limit: this.adminPages.eachPageNum
      };
      if (this.selectValue) {
        param.class_id = this.selectValue
      }
      if (this.auditValue) {
        param.type = this.auditValue
      }
      this.$httpteacher.axiosGetBy(this.$api.accountList, param, (res) => {
        if (res.code === 200) {
          this.accountList = res.list;
          this.accountSelectList = res.list;
          this.adminPages.total = res.total
        }
      })
    },
    //审核
    accountAudit(row) {
      this.studentName = row.name;
      this.studentAccount = row.index_url;
      this.avatar=row.avatar
      this.nickname=row.nickname
      this.accountId = row.id;
      this.accountStatus = row.audit_status;
      this.dialogVisible = true;
    },
    //选择班级
    isSelectClass(val) {
      this.getAccountList()
    },
    selectAudit(){
      this.getAccountList()
    },
    // 驳回审核
    auditNoPass() {
      let param = {
        id: this.accountId,
        status: 2
      };
      this.$httpteacher.axiosGetBy(this.$api.changeAccountState, param, (res) => {
        if (res.code === 200) {
          this.$message({
            type: 'error',
            message: '审核驳回',
            duration: 1000,
            onClose: () => {
              this.dialogVisible = false;
              this.getAccountList()
            }
          });
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      }, (err) => {
        console.log('错误', err)
      })
    },
    // 审核通过
    auditPass() {
      let param = {
        id: this.accountId,
        status: 1
      };
      this.$httpteacher.axiosGetBy(this.$api.changeAccountState, param, (res) => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '审核通过',
            duration: 1000,
            onClose: () => {
              this.dialogVisible = false;
              this.getAccountList();
            }
          });
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      }, (err) => {
        console.log('错误', err)
      })
    },
    adminCurrentChange(val) {
      this.adminPages.currentPageNum = val;
      this.getAccountList()
    },

    getAuditConfig() {
      this.$httpteacher.axiosGet(this.$api.getAuditConfig, (res) => {
        if (res.code === 200) {
          this.audit_type = res.data.audit_type;
        }
      });
    },

    handleChangeAudit(type) {
      // console.log("audit type: ", type);
      // if (type === this.audit_type) return;
      const prevAuditType = this.audit_type;

      const formData = new FormData();
      formData.append("audit_type", type);

      this.$httpteacher.axiosPost(this.$api.updateAuditConfig, formData, (res) => {
        if (res.code === 200) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
          this.audit_type = prevAuditType;
        }
      }, (err) => {
         this.$message.error(err);
          this.audit_type = prevAuditType;
      });
    },
  }
}
</script>
<style scoped lang="scss">
.AccountAudit {
  height: calc(100vh - 100px);
  background: #ffffff;
  display: flex;
  flex-direction: column;

  .AccountAuditHeader {
    color: #333333;
    font-size: 16px;
    line-height: 55px;
    border-bottom: 1px solid #F3F3F6;
    padding-inline: 60px;
    display: flex;
    justify-content: space-between;

    .audit-type {
      display: flex;
      gap: 20px;
      align-items: center;
    }
  }

  .selectClass {
    display: flex;
    padding: 27px 60px;
  }

}

::v-deep .pages-center {
  padding: 30px 0;
}

.el-table::before {
  display: none;
}
::v-deep .el-dialog__body{
  padding: 15px !important;
}

input[aria-hidden="true"] {
  display: none !important;
}
</style>
